;(function ($) {
    $.fn.extend({
        donetyping: function (callback, timeout) {
            timeout = timeout || 1e3; // 1 second default timeout
            var timeoutReference,
                doneTyping = function (el) {
                    if (!timeoutReference) return;
                    timeoutReference = null;
                    callback.call(el);
                };
            return this.each(function (i, el) {
                var $el = $(el);
                // Chrome Fix (Use keyup over keypress to detect backspace)
                // thank you @palerdot
                $el.is(':input') && $el.on('keyup keypress', function (e) {
                    // This catches the backspace button in chrome, but also prevents
                    // the event from triggering too premptively. Without this line,
                    // using tab/shift+tab will make the focused element fire the callback.
                    if (e.type == 'keyup' && e.keyCode != 8) return;

                    // Check if timeout has been set. If it has, "reset" the clock and
                    // start over again.
                    if (timeoutReference) clearTimeout(timeoutReference);
                    timeoutReference = setTimeout(function () {
                        // if we made it here, our timeout has elapsed. Fire the
                        // callback
                        doneTyping(el);
                    }, timeout);
                }).on('blur', function () {
                    // If we can, fire the event since we're leaving the field
                    doneTyping(el);
                });
            });
        }
    });
})(jQuery);


var login_timeout = null;

function updateLoginTime() {
    if (window.location.href.indexOf("login") > -1) {

    } else {
        if (login_timeout) {
            clearTimeout(login_timeout);
            login_timeout = null;
        }
        login_timeout = setTimeout(function () {
            $.ajax({
                url: '/update_lastactive',
                type: 'POST',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },

                success: function (data) {
                },
                error: function (error) {
                }

            });
        }, 4000);
    }
}

function checkLoginTime() {
    if (window.location.href.indexOf("login") > -1) {
        return;
    }

    setInterval(function () {
        $.ajax({
            url: '/login_time',

            success: function (data) {
                var obj = JSON.parse(data);

                if (obj.redirect) {
                    window.location = '/login_locked'
                }
            },
            error: function (error) {
                console.log(error);
            }

        });
    }, 25000);
}

$(document).ready(function () {
    checkLoginTime();

    $(document).keypress(function () {
        updateLoginTime();
    });
    $(document).click(function () {
        updateLoginTime();
    });
    $('input[type=text], textarea, .note-editable, .note-codable').donetyping(function () {
        updateLoginTime();
    });

    $('.bs-editable').editable({
        url: '/post'
    });

    $('.colorpick').colorpicker();

    var ranges = null;
    if (window.Translations) {
        ranges = {};
        ranges[window.Translations.today] = [moment(), moment()];
        ranges[window.Translations.yesterday] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[window.Translations.last_7_days] = [moment().subtract(6, 'days'), moment()];
        ranges[window.Translations.last_30_days] = [moment().subtract(29, 'days'), moment()];
        ranges[window.Translations.this_month] = [moment().startOf('month'), moment().endOf('month')];
        ranges[window.Translations.last_month] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
        ranges[window.Translations.this_year] = [moment().startOf('year'), moment().endOf('year')];
        ranges[window.Translations.last_year] = [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')];
    }
    $('.daterangepicker').daterangepicker({
        locale: {
            format: 'YYYY-MM-DD',
        },
        singleDatePicker: false,
        showDropdowns: true,
        autoApply: true,
        alwaysShowCalendars: true,
        ranges: ranges
    });
});

String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
